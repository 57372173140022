<template>
    <div class="c w-l services" id="services">

        <div class="services__intro mx-auto text-center mb-xxl w-12 s:w-9 m-up:w-6">
            <h3 class="weight-black mb-0">{{ title }}</h3>
            <div v-if="text" class="color-secondary" v-html="text" />
        </div>

        <div class="services__cards flex flex-wrap columns justify-center align-stretch">
            <template
                v-for="service in serviceList"
                :key="service.id"
            >
                <div
                    class="w-12 s-up:w-6 l-up:w-4"
                >
                        <router-link
                            :to="{ name: 'Service', params: { category: serviceGroups[service.category], id: service.id, slug: kebabcase(service.name) }}"
                            class="card color-contrast color-primary card--px py-m flex justify-between align-center"
                            :class="['bg-company-' + service.category, { 'hover:color-company-3': service.category !== 3, 'hover:color-company-2': service.category === 3 }]"
                        >
                            <span>{{ service.name }}</span>
                            <icon class="size-5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"/></svg>
                            </icon>
                        </router-link>
                </div>
            </template>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import kebabcase from 'lodash.kebabcase';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'ServicesLinked',
    props: {
        title: String,
        text: String,
        ids: {
            type: Array as PropType<number[]>,
        },
    },
    setup(props) {
        const { services } = useTexts();
        const serviceList = computed(() => {
            if (services.value && Array.isArray(props.ids)) {
                // @ts-ignore
                return services.value.filter((it) => props.ids.includes(it.id));
            }
            return [];
        });

        return {
            kebabcase,
            serviceList,
            serviceGroups: ['', 'huis', 'bedrijf', 'familie'],
        };
    },
});
</script>

<style>
    #services {
        padding-top: 7vh;
        padding-bottom: 7vh;
    }
        .services__list a {
            padding: var(--size) calc(var(--card-px) - var(--size-s));
            border-radius: var(--radius);
            margin-bottom: var(--size-s);
        }
        .bg-company-1 .services__list a {
            background: var(--color-company-1--linkbg);
        }
        .bg-company-2 .services__list a {
            background: var(--color-company-2--linkbg);
        }
        .bg-company-3 .services__list a {
            background: var(--color-company-3--linkbg);
        }
</style>
