<template>
    <section id="team" class="mt-xxl pt-xxl fadeInDown">
        <h3>Dit is ons team</h3>

        <div class="flex flex-wrap align-stretch py-xxl l-up:pt-0 columns">
            <div
                v-for="member in data"
                :key="member.id"
                class="w-12 s-up:w-6 l:w-4 xl:w-3"
            >
                <a
                    :href="member.email ? 'mailto:' + member.email : undefined"
                    class="card bg-company-3 color-contrast relative flex flex-col bg-icons--huis"
                    :class="{
                        'justify-end card--hasbgimage': member.image,
                        'card--nobgimage': !member.image,
                        'hover:opacity-75': member.email,
                        'cursor-none': !member.email,
                    }"
                >
                    <img
                        v-if="member.image"
                        :srcset="imageSizes(cdnUrl(member.image), [400, 800, 1200])"
                        :alt="member.name"
                        class="z-1 absolute card__bgimage"
                    />
                    <div
                        class="card__details color-primary relative z-5 card--px card--pt card--pb w-12 flex justify-between align-center color-secondary"
                        :class="{ 'flex flex-col flex-grow': !member.image }"
                    >
                        <div class="w-12">
                            <h3 class="size-4 mb-0">{{ member.name }}</h3>
                            <div>{{ member.function }}</div>
                        </div>
                        <icon class="size-5" v-if="member.email">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail" viewBox="0 0 24 24"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><path d="M22 6l-10 7L2 6"/></svg>
                        </icon>
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';

export default defineComponent({
    name: 'Team',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return {
            cdnUrl,
            imageSizes,
        };
    },
});
</script>
