
import {
    defineComponent, ref, onMounted, watch, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { METADATA } from '@projectfive/v-meta';
import { imageSizes, replaceBrackets } from '@projectfive/services';
import RichText from '@projectfive/v-richtext';
import ServiceCards from '@/components/ServiceCards.vue';
import NewsCards from '@/components/NewsCards.vue';
import Team from '@/components/Team.vue';
import ServicesLinked from '@/components/ServicesLinked.vue';
import http from '../services/http';
import { reportError } from '../services/errorhandling';
// Composables
import useLoading from '../composables/loading';
// Components
import '@projectfive/v-richtext/dist/style.css';

export default defineComponent({
    name: 'Article',
    components: {
        RichText,
        ServiceCards,
        NewsCards,
        ServicesLinked,
        Team,
    },
    props: {
        category: String,
        slug: String,
        id: String,
    },
    setup(props) {
        const data = ref({});
        const blocks = ref({});
        const firstImageBlock = ref(undefined);

        const route = useRoute();

        onMounted(getData);
        watch(() => route.meta, getData, { flush: 'post' });

        const { loadStates } = useLoading();
        function getData() {
            const articleMeta: any = route.meta.article;
            if (articleMeta && articleMeta.endpoint) {
                const url = replaceBrackets(articleMeta.endpoint, props);
                const params = articleMeta.params || {};
                loadStates.add(url);
                http.get(url, { params })
                    .then((r) => {
                        processData(r.results);
                    }).catch((err) => {
                        reportError(err, {
                            extra: { url },
                            tags: { section: 'get-article' },
                        });
                    }).finally(() => {
                        loadStates.remove(url);
                    });
            }
        }

        const metadata = inject(METADATA);
        function processData(r) {
            const item = r;
            data.value = r;
            if (item.config && item.config.content) {
                if (item.config.content.length && item.config.content[0].type === 'image') {
                    firstImageBlock.value = item.config.content[0].attrs;
                    const blocksData = JSON.parse(JSON.stringify(item.config));
                    blocksData.content = blocksData.content.slice(1);
                    blocks.value = blocksData;
                } else {
                    blocks.value = item.config;
                }
            }

            // @ts-ignore
            metadata.set({
                metadata: {
                    title: r.name,
                },
            });
        }

        return {
            data,
            blocks,
            firstImageBlock,
            imageSizes,
            blockSettings: {
                image: {
                    attrs: { class: 'w-m' },
                    index: {
                        0: { attrs: { class: 'hide' } },
                    },
                },
                embed: {
                    attrs: { class: 'w-m' },
                },
                attrs: {
                    class: 'w-s mx-auto',
                },
            },
        };
    },
});
