
import { defineComponent, computed, PropType } from 'vue';
import kebabcase from 'lodash.kebabcase';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'ServicesLinked',
    props: {
        title: String,
        text: String,
        ids: {
            type: Array as PropType<number[]>,
        },
    },
    setup(props) {
        const { services } = useTexts();
        const serviceList = computed(() => {
            if (services.value && Array.isArray(props.ids)) {
                // @ts-ignore
                return services.value.filter((it) => props.ids.includes(it.id));
            }
            return [];
        });

        return {
            kebabcase,
            serviceList,
            serviceGroups: ['', 'huis', 'bedrijf', 'familie'],
        };
    },
});
