<template>
    <section class="articlepage bg-icons pb-xxl">
        <div class="c w-xl">
            <div class="ratio-2by1" v-if="firstImageBlock && firstImageBlock.src">
                <img
                    :src="firstImageBlock.src"
                    :srcset="imageSizes(firstImageBlock.src, [400, 800, 1200, 2400])"
                    class="ratio-stretch object-cover rounded-b-m"
                    :alt="firstImageBlock.alt || ''"
                    :title="firstImageBlock.alt || ''"
                    loading="lazy"
                />
                <h2
                    v-html="firstImageBlock.alt"
                    class="firstImageBlock color-white rounded-b-m px-xl pt-l pb-l"
                />
            </div>
        </div>
        <div class="c w-xl flex flex-wrap justify-between">
            <div
                class="w-12 s-up:w-10 s:mx-auto m:mx-auto l-up:w-8 mt-xxl"
            >
                <RichText
                    :data="blocks"
                    :settings="blockSettings"
                />

                <ServicesLinked
                    v-if="data.linked_services && data.linked_services.length"
                    :ids="data.linked_services.map((it) => it.id)"
                    title="Gerelateerde services:"
                />

                <Team
                    v-if="data.team && data.team[0] && data.team[0].items"
                    :data="data.team[0].items"
                />
            </div>

            <aside class="w-12 l:pl-xl l-up:w-4 xl:w-3 pt-xxl m-down:mt-xxl">
                <ServiceCards
                    v-if="$route.meta.article.services"
                    mode="sidebar"
                />
                <div
                    v-if="$route.meta.article.news"
                    class="flex flex-wrap columns justify-center align-stretch"
                >
                    <NewsCards
                        mode="sidebar"
                        :excludeIDs="[+id]"
                    />
                </div>
            </aside>
        </div>
    </section>
</template>

<script lang="ts">
import {
    defineComponent, ref, onMounted, watch, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { METADATA } from '@projectfive/v-meta';
import { imageSizes, replaceBrackets } from '@projectfive/services';
import RichText from '@projectfive/v-richtext';
import ServiceCards from '@/components/ServiceCards.vue';
import NewsCards from '@/components/NewsCards.vue';
import Team from '@/components/Team.vue';
import ServicesLinked from '@/components/ServicesLinked.vue';
import http from '../services/http';
import { reportError } from '../services/errorhandling';
// Composables
import useLoading from '../composables/loading';
// Components
import '@projectfive/v-richtext/dist/style.css';

export default defineComponent({
    name: 'Article',
    components: {
        RichText,
        ServiceCards,
        NewsCards,
        ServicesLinked,
        Team,
    },
    props: {
        category: String,
        slug: String,
        id: String,
    },
    setup(props) {
        const data = ref({});
        const blocks = ref({});
        const firstImageBlock = ref(undefined);

        const route = useRoute();

        onMounted(getData);
        watch(() => route.meta, getData, { flush: 'post' });

        const { loadStates } = useLoading();
        function getData() {
            const articleMeta: any = route.meta.article;
            if (articleMeta && articleMeta.endpoint) {
                const url = replaceBrackets(articleMeta.endpoint, props);
                const params = articleMeta.params || {};
                loadStates.add(url);
                http.get(url, { params })
                    .then((r) => {
                        processData(r.results);
                    }).catch((err) => {
                        reportError(err, {
                            extra: { url },
                            tags: { section: 'get-article' },
                        });
                    }).finally(() => {
                        loadStates.remove(url);
                    });
            }
        }

        const metadata = inject(METADATA);
        function processData(r) {
            const item = r;
            data.value = r;
            if (item.config && item.config.content) {
                if (item.config.content.length && item.config.content[0].type === 'image') {
                    firstImageBlock.value = item.config.content[0].attrs;
                    const blocksData = JSON.parse(JSON.stringify(item.config));
                    blocksData.content = blocksData.content.slice(1);
                    blocks.value = blocksData;
                } else {
                    blocks.value = item.config;
                }
            }

            // @ts-ignore
            metadata.set({
                metadata: {
                    title: r.name,
                },
            });
        }

        return {
            data,
            blocks,
            firstImageBlock,
            imageSizes,
            blockSettings: {
                image: {
                    attrs: { class: 'w-m' },
                    index: {
                        0: { attrs: { class: 'hide' } },
                    },
                },
                embed: {
                    attrs: { class: 'w-m' },
                },
                attrs: {
                    class: 'w-s mx-auto',
                },
            },
        };
    },
});
</script>
<style>
    h2.firstImageBlock {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        top: auto;
        height: unset;
        margin: 0;
    }
</style>
