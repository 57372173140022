
import { defineComponent } from 'vue';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';

export default defineComponent({
    name: 'Team',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return {
            cdnUrl,
            imageSizes,
        };
    },
});
